import FETCH_STATUS from 'constants/fetchStatus';
import produce from 'immer';
import immerPackHandler from 'utils/redux-pack/handlePack';
import {
  ENQUEUE_FOR_SUBMISSION,
  QUESTION_SUBMISSION_STATUS,
  QUESTION_TYPE,
  RESET_PROM,
  START_PROM,
  START_SUBMISSION,
  SUBMIT_PROM,
  SUBMIT_QUESTION,
  SUBMIT_QUESTIONNAIRE,
  UPDATE_ANSWERS
} from './constants';
import { buildAnswer, buildQuestion } from './parsers';
import { validateQuestion } from './validators';

const initialState = {
  questions: {
    data: [],
    nextQuestionIndexCounter: 1,
    remainingQuestions: -1
  },
  submission: {
    currentQuestionnaireIndex: 0,
    questionnaireStatuses: [],
    submissionStatus: FETCH_STATUS.UNSET
  },
  answers: {},
  assessment: {
    id: null,
    type: null,
    timing: null,
    diseaseSlug: null,
    submissionStatus: FETCH_STATUS.UNSET
  },
  configs: {
    institution: null
  },
  sections: []
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case RESET_PROM: {
      return initialState;
    }
    case UPDATE_ANSWERS: {
      const { questionKey: answeredQuestionKey, answers, isSubmittableOnChange } = action.payload;
      draft.answers = {
        ...draft.answers,
        ...buildAnswer({ key: answeredQuestionKey, data: answers })
      };
      draft.questions.data = draft.questions.data.map(q => {
        if (q.key === answeredQuestionKey) {
          const validationResult = validateQuestion(q, answers);
          q.isSubmittableOnChange = isSubmittableOnChange;
          [q.validation.isValid, q.validation.errors] = validationResult;
        }

        return q;
      });
      break;
    }
    case START_PROM: {
      immerPackHandler(draft, action, {
        success: prevState => {
          const {
            questionnaire: { first_question: firstQuestion },
            id: assessmentId,
            key: timing,
            answers,
            type,
            institution,
            disease_uid: diseaseSlug
          } = action.payload.data;
          const { question: questionParsed } = buildQuestion({
            ...firstQuestion,
            isSubmittableOnChange: true,
            isValid: true
          });

          prevState.questions.data = [questionParsed];
          prevState.assessment.id = assessmentId;
          prevState.assessment.type = type;
          prevState.assessment.timing = timing;
          prevState.assessment.diseaseSlug = diseaseSlug;
          prevState.configs.institution = { ...institution };
          const answersParsed = Object.entries(answers).reduce(
            (p, c) => ({
              ...p,
              ...buildAnswer({ key: c[0], data: c[1], alreadySubmitted: true })
            }),
            {}
          );

          prevState.answers = { ...answersParsed };
          prevState.questions.nextQuestionIndexCounter = prevState.questions.data.filter(
            question => question.type === QUESTION_TYPE.QUESTION && question.key !== 'START'
          ).length;

          // New questionnaires model
          prevState.submission.questionnaireStatuses[prevState.submission.currentQuestionnaireIndex] =
            FETCH_STATUS.START;
        }
      });
      break;
    }
    case SUBMIT_QUESTION: {
      immerPackHandler(draft, action, {
        start: prevState => {
          prevState.questions.data = prevState.questions.data.map(q => {
            if (q.id === action.meta.question.id) {
              q.submissionStatus = QUESTION_SUBMISSION_STATUS.LOADING;
            }

            return q;
          });
        },
        success: prevState => {
          const { next_question: nextQuestion, remaining_questions: remainingQuestions } = action.payload.data;
          const {
            question: { id: submittedQuestionId }
          } = action.meta;
          const { question: nextQuestionParsed } = buildQuestion({ ...nextQuestion });
          prevState.questions.remainingQuestions = remainingQuestions;

          prevState.questions.data = prevState.questions.data.map(q => {
            if (q.id === submittedQuestionId) {
              q.submissionStatus = QUESTION_SUBMISSION_STATUS.SUCCESS;
              q.nextQuestionId = nextQuestionParsed.id;
            }

            return q;
          });

          if (nextQuestionParsed?.block && nextQuestionParsed.block !== '') {
            prevState.sections = prevState.sections.map(section => ({
              ...section,
              selected: section.title === nextQuestionParsed.block,
              completed: false
            }));

            if (!prevState.sections.some(section => section.title === nextQuestionParsed.block)) {
              prevState.sections.push({ title: nextQuestionParsed.block, selected: true, completed: false });
            }
          }

          if (nextQuestionParsed.type === QUESTION_TYPE.FINISH) {
            // Worflow for assessments
            prevState.assessment.submissionStatus = FETCH_STATUS.SUCCESS;

            // Workflow for questionnaires
            prevState.submission.questionnaireStatuses[prevState.submission.currentQuestionnaireIndex] =
              FETCH_STATUS.FINISH;

            if (prevState.submission.submissionStatus !== FETCH_STATUS.UNSET) {
              const submitedQuestionPosition = prevState.questions.data.findIndex(q => q.id === submittedQuestionId);
              const nextQuestionPosition = submitedQuestionPosition + 1;
              const nextPrevStateQuestion = prevState.questions.data[nextQuestionPosition] || undefined;

              if (nextPrevStateQuestion?.id === nextQuestionParsed.id) {
                return;
              }

              if (nextQuestionPosition < prevState.questions.data.length) {
                prevState.questions.data = prevState.questions.data.slice(0, nextQuestionPosition);
              }
            }
          } else {
            const submitedQuestionPosition = prevState.questions.data.findIndex(q => q.id === submittedQuestionId);
            const nextQuestionPosition = submitedQuestionPosition + 1;
            const nextPrevStateQuestion = prevState.questions.data[nextQuestionPosition] || undefined;

            if (nextPrevStateQuestion?.id === nextQuestionParsed.id) {
              return;
            }

            if (nextQuestionPosition < prevState.questions.data.length) {
              prevState.questions.data = prevState.questions.data.slice(0, nextQuestionPosition);
            }

            prevState.questions.data.push(nextQuestionParsed);
            prevState.questions.nextQuestionIndexCounter = prevState.questions.data.filter(
              question => question.type === QUESTION_TYPE.QUESTION && question.key !== 'START'
            ).length;

            prevState.submission.questionnaireStatuses[prevState.submission.currentQuestionnaireIndex] =
              FETCH_STATUS.START;
          }
        },
        failure: prevState => {
          prevState.questions.data = prevState.questions.data.map(q => {
            if (q.id === action.meta.question.id) {
              q.submissionStatus = QUESTION_SUBMISSION_STATUS.FAILURE;
            }

            return q;
          });
        }
      });

      break;
    }
    case ENQUEUE_FOR_SUBMISSION: {
      draft.questions.data = draft.questions.data.map(q => {
        if (q.id === action.payload.questionId) {
          q.submissionStatus = QUESTION_SUBMISSION_STATUS.IN_QUEUE;
        }

        return q;
      });
      break;
    }
    case SUBMIT_PROM: {
      immerPackHandler(draft, action, {
        start: prevState => {
          prevState.assessment.submissionStatus = FETCH_STATUS.LOADING;
        },
        success: prevState => {
          prevState.assessment.submissionStatus = FETCH_STATUS.SUCCESS;
        }
      });
      break;
    }
    case START_SUBMISSION: {
      draft.submission.questionnaireStatuses = [FETCH_STATUS.START].concat(
        Array((action.payload.questionnairesLength || 1) - 1).fill(FETCH_STATUS.UNSET)
      );
      draft.submission.submissionStatus = FETCH_STATUS.START;
      break;
    }
    case SUBMIT_QUESTIONNAIRE: {
      draft.submission.questionnaireStatuses[draft.submission.currentQuestionnaireIndex] = FETCH_STATUS.SUCCESS;

      if (draft.submission.questionnaireStatuses.some(status => status !== FETCH_STATUS.SUCCESS)) {
        draft.submission.currentQuestionnaireIndex += 1;
        draft.questions.remainingQuestions = -1;
        return;
      }

      draft.submission.submissionStatus = FETCH_STATUS.SUCCESS;
      break;
    }
    default: {
      // do nothing
    }
  }
}, initialState);

export { initialState };
export default reducer;
